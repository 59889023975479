import React, { useContext } from 'react';

import LinkList from '@helsenorge/designsystem-react/components/LinkList';

import { getAbsoluteUrl } from '@helsenorge/core-cms/shared/links';

import { globalStateContext } from './../../../store';

const MenuProfileLinks: React.FC = () => {
  const globalState = useContext(globalStateContext);

  if (globalState?.bruker?.harInnsynssperre || !globalState.headerFooter?.header.profileMenu?.linkList) {
    return null;
  }

  return (
    <LinkList color={'neutral'}>
      {globalState.headerFooter.header.profileMenu.linkList.map((link, index) => (
        <LinkList.Link
          key={index}
          href={getAbsoluteUrl(link)}
          target={link.target ?? undefined}
          title={link.title ?? undefined}
          className="menu-profile-links__anchor"
        >
          {link.text}
        </LinkList.Link>
      ))}
    </LinkList>
  );
};

export default MenuProfileLinks;
