/**
 * Send data til Adobe Analytics via adobeDataLayer
 *
 * @param payload Data som skal spores
 */
export const pushToDataLayer = (payload) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(payload);
};
/**
 * Spor handling i tjeneste
 * @param trackAction type handling som skal spores
 * @param serviceName navnet på tjenesten, f.eks. "pasientreiser"
 * @param action spesifiserer hvilken handling man utfører, f.eks. "send søknad"
 * @param flow type prosess
 */
export const trackSelfService = (trackAction, serviceName, action, flow) => {
    const payload = {
        event: `self service ${trackAction}`,
        selfService: {
            name: `${serviceName}: ${action}`,
            flow,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor bruk av samtykkeflyt
 * @param action definerer consentStart, consentFunnel eller consentComplete videre
 * @param stepName definerer consentFunnelStep på ConsentSelfService
 * @param stepNumber definerer selfServiceFunnelStepNumber på ConsentSelfService
 * @param type definerer consentType på ConsentSelfService
 */
export const trackConsent = (action, stepName, stepNumber, type) => {
    const payload = {
        event: `consent ${action}`,
        consent: {
            stepName,
            stepNumber,
            type,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor bruk av filterkomponent
 * @param name definerer navnet fil Filter
 * @param action definerer type tracking: 'usage' | 'expand' | 'search' | 'group expand'
 */
export const trackFilters = (name, action) => {
    const payload = {
        event: `filter ${action}`,
        filter: {
            name,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor partner - setter informasjon om tjenesteeier
 * @param partner definerer partner
 */
export const updatePagePartner = (partner) => {
    const payload = {
        page: {
            partner,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Setter informasjon om innlogget status i window.adobeDataLayer
 * @param loggedIn Om bruker er logget inn eller ikke
 */
export const updateLoginStatus = (loggedIn) => {
    const payload = {
        user: {
            loggedIn,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor uleste varslinger
 * @param unreadAlerts Antall uleste varslinger
 */
export const trackUnreadAlert = (unreadAlerts) => {
    const payload = {
        event: 'unread alerts',
        notifications: {
            unreadAlerts,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor uleste meldinger
 * @param unreadMessages Antall uleste meldinger
 */
export const trackUnreadMessage = (unreadMessages) => {
    const payload = {
        event: 'unread messages',
        notifications: {
            unreadMessages,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor leste meldinger med type.
 * @param type Meldingstype
 */
export const trackReadMessageType = (type) => {
    const payload = {
        event: 'message read',
        message: {
            type,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor internsøk
 * @param phrase nøkkelord som settes på search searchTerm
 * @param type type som settes på search searchType
 * @param resultsCount antall søkeresultater som settes på search resultsCount
 */
export const trackSearch = (phrase, type, resultsCount) => {
    const payload = {
        event: 'internal search',
        search: {
            phrase,
            type,
            resultsCount,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor klikk på internsøkeresultater
 * @param searchPosition position til søkeresultatet som lagres på search searchposition
 */
export const trackSearchThrough = (searchPosition) => {
    const payload = {
        event: 'search click through',
        search: {
            searchPosition,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor endring av profilinnstillinger
 * @param interactionName navn som lagres på user profileInteractionName
 */
export const trackProfileInteraction = (interactionName) => {
    const payload = {
        event: 'profile interaction',
        profile: {
            interactionName,
        },
    };
    pushToDataLayer(payload);
};
/**
 * spor handlinger med kun ett steg, e.g.
 * trackTool('kontaktskjema', 'hjelp', 'bytte fastlege', 'send')
 * @param name navn som lagres på tool toolName
 * @param type type som lagres på tool toolType
 * @param labels label som lagres på tool toolLabels
 * @param action handlingen som utføres
 */
export const trackTool = (name, type, labels, action) => {
    const payload = {
        event: 'use tool',
        tool: {
            name,
            type,
            labels,
            action,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor åpning/lukking av hjelpeskuff
 * @param name navn som lagres på tool.name
 * @param type type som lagres på tool.type
 * @param labels labels som lagres på tool.labels
 * @param action 'Close' | 'Open'
 */
export const trackProsesshjelp = (name, type, labels, action) => {
    trackTool(name, type, labels, action);
};
/**
 * Oppdater data om brukeren, f.eks.
 * updateUserAttributes({ userStatus: "something" })
 * @param userAttributes objekt med verdier som enten legges til eller oppdaterer eksisterende data om brukeren
 */
export const updateUserAttributes = (userAttributes) => {
    const payload = {
        user: userAttributes,
    };
    pushToDataLayer(payload);
};
/**
 * spor sidevisninger, e.g.
 * trackPageview()
 * @param hasContent (valgfri) om siden har innhold
 */
export const trackPageview = (hasContent) => {
    const payload = {
        event: 'pageview',
        page: {
            hasContent: !!hasContent,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor om en tjeneste har innhold eller ikke
 * @param hasContent Boolean som sier om det er innhold i tjenesten eller ikke
 */
export const trackHasContent = (hasContent) => {
    trackPageview(hasContent);
};
/**
 * Spor feil, f.eks.
 * trackError("level1", "Beskrivelse av feilen")
 * @param level Alvorlighetsgrad (level1, level2, level3 eller level4)
 * @param details Tekst som beskriver feilen
 */
export const trackError = (level, details) => {
    const payload = {
        event: 'site error',
        error: {
            level,
            details,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor klikk i toppmenyen
 * @param name string
 * @param label navigationLabel
 * @param pageSection navigationPageSection
 */
export const trackNavigation = (name, label, pageSection) => {
    const payload = {
        event: 'navigation',
        navigation: {
            name,
            label,
            pageSection,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor tilbakemelding i invitasjon til spørreundersøkelse
 * @param name string
 * @param label string
 */
export const trackFeedback = (name, label) => {
    const payload = {
        event: 'feedback',
        feedback: {
            name,
            label,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor 404-feil
 */
export const track404 = () => {
    const payload = {
        event: '404 not found',
    };
    pushToDataLayer(payload);
};
/**
 * Spor at bruker bytter språk.
 * trackLanguage('English')
 * @param language Språket bruker valgte.
 */
export const trackLanguage = (language) => {
    const payload = {
        event: 'change language',
        page: {
            language,
        },
    };
    pushToDataLayer(payload);
};
/**
 * Spor endring i hvem brukeren representerer
 * @param onBehalfOf Hvilken type representasjon er det
 * @param role Hvilken rolle har bruker i representasjonen
 */
export const trackRepresentation = (onBehalfOf, role) => {
    const payload = {
        user: {
            representation: {
                onBehalfOf,
                role,
            },
        },
    };
    pushToDataLayer(payload);
};
