import { Dispatch } from 'react';

import { HeaderFooterFallbackResponse, InnloggetDataResponse } from '../types/entities';
import { ActionTypes } from '../types/entities';
import { HentAntallUlesteResponse, HentKunAntallUlesteHendelserResponse } from '../types/entities/hendelsesmeny.v2entities';
import {
  HentRepresentasjonsforholdV1Response,
  PersonvelgerElement,
  SettRepresentasjonsforholdV1Request,
} from '../types/entities/representasjon.v1entities';
import { RepresentasjonsforholdType } from '../types/entities/representasjon.v1enums';

import { HeaderFooterResponse } from '@helsenorge/core-cms/types/entities';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { merge } from '@helsenorge/core-utils/object-utils';
import { get as ContentAPIGet } from '@helsenorge/framework-utils/cms-content-api-service';
import { hasCookie, hasLoggedInCookie } from '@helsenorge/framework-utils/cookie';
import { isAuthorized as isAuthorizedFromCoreUtils } from '@helsenorge/framework-utils/hn-authorize';
import { isDebug } from '@helsenorge/framework-utils/hn-debug';
import { get as getProxy, post, erTjenester, getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { get as getTjeneste } from '@helsenorge/framework-utils/hn-tjeneste-service';
import {
  getHarRepresentasjon,
  getInnloggetFulltNavn,
  getSistPalogget,
  getSamtykkekonverteringInformert,
  getErRepresentasjon,
  getErMellom12Og16,
  getRepresentertBruker,
  getVisPersonvelger,
  getHarInnsynssperre,
  getScopes,
  getPersonverninnstillinger,
} from '@helsenorge/framework-utils/hn-user';
import { error as logError, warn } from '@helsenorge/framework-utils/logger';
import { OperationResponse } from '@helsenorge/framework-utils/types/entities';
import { HNeventSetFooterData } from '@helsenorge/framework-utils/web-component/events';

import { GlobalState, RepresentasjonerState, BrukerState } from './initialState';

// TODO: Burde splitte opp denne fila i flere filer, og legge under /actions-mappa

export interface DispatchAction {
  type: ActionTypes;
  payload: Partial<GlobalState>;
}

export const getUser = (dispatch: Dispatch<DispatchAction>, errorText: string): void => {
  try {
    const bruker: BrukerState = {
      visPersonvelger: getVisPersonvelger(),
      navn: getInnloggetFulltNavn(),
      sistInnlogget: getSistPalogget(),
      harRepresentasjon: getHarRepresentasjon(),
      samtykkeKonverteringInformert: getSamtykkekonverteringInformert(),
      representertBruker: getRepresentertBruker(),
      erRepresentasjon: getErRepresentasjon(),
      erMellom12Og16: getErMellom12Og16(),
      harBarnMedAnnenAdresse: false,
      harBarnUnder16UtenForeldreansvar: false,
      harBarnOver11: false,
      harBarnOver12: false,
      harBarnOver16: false,
      scopes: getScopes(),
      personverninnstillinger: getPersonverninnstillinger(),
      harInnsynssperre: getHarInnsynssperre(),
    };

    dispatch({
      type: ActionTypes.GetUserAction,
      payload: { bruker },
    });
  } catch (e) {
    const payload: Partial<GlobalState> = {
      error: { profileError: errorText },
      loading: { global: false },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved henting av brukerdata', e);
  }
};

export const erInnloggetOgErTjenester = (dispatch: Dispatch<DispatchAction>): void => {
  const isAuthorized = hasLoggedInCookie() || isAuthorizedFromCoreUtils();
  const isMinHelseTjenester = erTjenester();

  dispatch({
    type: ActionTypes.SetIsAuthorizedAction,
    payload: { isAuthorized, isMinHelseTjenester },
  });
};

export const processRepresentasjonsForhold = (
  Representasjonsforhold?: PersonvelgerElement[]
): { represenasjonsState?: RepresentasjonerState; erRepresentasjon?: boolean } => {
  const returnData = { represenasjonsState: undefined, erRepresentasjon: undefined } as {
    represenasjonsState?: RepresentasjonerState;
    erRepresentasjon?: boolean;
  };
  const represenasjonsState = {} as RepresentasjonerState;
  if (Representasjonsforhold) {
    const currentRepresentasjon = Representasjonsforhold.find(r => r.selected);
    represenasjonsState.currentRepresentasjon = currentRepresentasjon;
    represenasjonsState.andreRepresentasjoner = Representasjonsforhold;
    const erRepresentasjon = currentRepresentasjon?.representasjonsforholdType !== RepresentasjonsforholdType.innloggetBruker;
    returnData.erRepresentasjon = erRepresentasjon;
    returnData.represenasjonsState = represenasjonsState;
    return returnData;
  } else {
    return returnData;
  }
};

export const getInnloggetData = async (dispatch: Dispatch<DispatchAction>, errorText: string): Promise<void> => {
  try {
    const e = await getTjeneste<InnloggetDataResponse>('/api/v1/CrossDomain/InnloggetData');
    let payload: Partial<GlobalState> = {};

    const bruker: BrukerState = {
      visPersonvelger: !hasCookie('HN-ValgtRepresentasjon'),
      navn: e?.InnloggetData.RepresentertBruker,
      sistInnlogget: e?.InnloggetData.SistPaloggetDato,
      harRepresentasjon: e?.InnloggetData.HarRepresentasjon,
      samtykkeKonverteringInformert: true,
      representertBruker: e?.InnloggetData.RepresentertBruker,
      erMellom12Og16: e?.InnloggetData.ErMellom12Og16,
      scopes: e?.InnloggetData.Scopes,
      personverninnstillinger: e?.InnloggetData.AktiveUtvalgtePersonverninnstillinger,
      harInnsynssperre: e?.InnloggetData.HarInnsynssperre,
    };
    payload = { bruker };

    payload = { ...payload, loading: { global: false } };

    dispatch({
      type: ActionTypes.GetUserAction,
      payload,
    });
  } catch (e) {
    const payload: Partial<GlobalState> = {
      error: { profileError: errorText },
      loading: { global: false },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved henting av innlogget data', e);
  }
};

export const getRepresentasjonsforhold = async (dispatch: Dispatch<DispatchAction>, errorText: string): Promise<void> => {
  dispatch({
    type: ActionTypes.SetLoadingAction,
    payload: {
      loading: {
        isRepresentasjonsforholdLoading: true,
      },
    },
  });
  try {
    const representasjoner = await getProxy<HentRepresentasjonsforholdV1Response>('representasjon', 'representasjonsforhold/v1');
    if (representasjoner === null) {
      throw new Error('Respons fra representasjon-API er null');
    }
    const processedRepresentasjonsForhold = processRepresentasjonsForhold(representasjoner.representasjonsforhold);
    dispatch({
      type: ActionTypes.GetRepresentasjonAction,
      payload: {
        loading: {
          isRepresentasjonsforholdLoading: false,
        },
        error: {
          representasjonsforholdError: undefined,
        },
        representasjoner: processedRepresentasjonsForhold?.represenasjonsState,
        bruker: {
          erRepresentasjon: processedRepresentasjonsForhold?.erRepresentasjon,
          harBarnMedAnnenAdresse: representasjoner.harBarnMedAnnenAdresse,
          harBarnUnder16UtenForeldreansvar: representasjoner.harBarnUnder16UtenForeldreansvar,
          harBarnOver11: representasjoner.harBarnOver11,
          harBarnOver12: representasjoner.harBarnOver12,
          harBarnOver16: representasjoner.harBarnOver16,
        },
      },
    });
  } catch (e) {
    dispatch({
      type: ActionTypes.error,
      payload: {
        error: {
          representasjonsforholdError: errorText,
        },
        loading: {
          isRepresentasjonsforholdLoading: false,
        },
      },
    });
    logError('Feil ved henting av representasjonsforhold', e);
  }
};

export type OnSetRepresentasjonForholdCallback = (newPersonHasBeenSelected: boolean) => void;

export const setRepresentasjonForhold = async (
  dispatch: Dispatch<DispatchAction>,
  errorText: string,
  User?: PersonvelgerElement,
  onSetRepresentasjonForhold?: OnSetRepresentasjonForholdCallback
): Promise<void> => {
  try {
    const newPersonHasBeenSelected = User?.selected === false;

    dispatch({
      type: ActionTypes.SwitchUserAction,
      payload: {
        representasjoner: {
          currentRepresentasjon: User,
          changedUser: true,
        },
      },
    });

    const response = await post<OperationResponse, SettRepresentasjonsforholdV1Request>('representasjon', 'representasjonsforhold/v1', {
      profilGuid: User?.profilGuid,
    });

    if (response === null) {
      throw new Error('Respons fra representasjon-API er null');
    }

    if (onSetRepresentasjonForhold) {
      onSetRepresentasjonForhold(newPersonHasBeenSelected);
    } else {
      // This catch is for asynchronous errors during the request
      dispatch({
        type: ActionTypes.error,
        payload: {
          error: { profileError: errorText },
          loading: { global: false },
        },
      });
      if (response.ErrorMessage && response.StatusCode !== 403) {
        logError(response.ErrorMessage.Title, response.ErrorMessage.Body);
      }
    }
  } catch (e) {
    // this catch is for any other error that could happen during runtime
    const payload: Partial<GlobalState> = {
      error: { profileError: errorText },
      loading: { global: false },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved setting av representasjonforhold', e);
  }
};

export const loggUt = (innbyggermenyLoggUtUrl: string): void => {
  const HelseNorgeUrlLogut = `${getTjenesterUrl()}${innbyggermenyLoggUtUrl}`;

  window.location.replace(HelseNorgeUrlLogut);
};

export const getAntallUlesteForAlle = (dispatch: Dispatch<DispatchAction>, errorText: string): void => {
  try {
    dispatch({ type: ActionTypes.SetLoadingAction, payload: { loading: { isAntallUlesteLoading: true } } });
    getProxy<HentAntallUlesteResponse>('Hendelsesmenyinternal', 'api/v2/AntallUleste')
      .then(r => {
        const payload: Partial<GlobalState> = {
          antallUlesteForAlleRepresentasjoner: r?.antallUlesteRepresentertGuids,
          loading: { isAntallUlesteLoading: false },
          error: { antallUlesteError: undefined },
        };
        dispatch({ type: ActionTypes.GetAntallUlesteForAlle, payload });
      })
      .catch((e: OperationResponse) => {
        // This catch is for asynchronous errors during the request
        dispatch({
          type: ActionTypes.error,
          payload: {
            loading: { isKunAntallUlesteLoading: false },
            error: { antallUlesteError: errorText },
          },
        });
        if (e.ErrorMessage && e.StatusCode !== 403) {
          logError(e.ErrorMessage.Title, e.ErrorMessage.Body);
        }
      });
  } catch (e) {
    // this catch is for any other error that could happen during runtime
    const payload: Partial<GlobalState> = {
      loading: { isKunAntallUlesteLoading: false },
      error: { antallUlesteError: errorText },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved henting av antall uleste', e);
  }
};

export const getKunAntallUleste = (dispatch: Dispatch<DispatchAction>, errorText: string): void => {
  try {
    dispatch({ type: ActionTypes.SetLoadingAction, payload: { loading: { isKunAntallUlesteLoading: true } } });
    getProxy<HentKunAntallUlesteHendelserResponse>('Hendelsesmenyinternal', 'api/v2/Hendelse/KunAntallUleste')
      .then(r => {
        const payload: Partial<GlobalState> = {
          antallUlesteForValgtRepresentasjon: r?.antallUlesteHendelser,
          loading: { isKunAntallUlesteLoading: false },
          error: { kunAntallUlesteError: undefined },
        };
        dispatch({ type: ActionTypes.GetKunAntallUleste, payload });
      })
      .catch((e: OperationResponse) => {
        // This catch is for asynchronous errors during the request
        dispatch({
          type: ActionTypes.error,
          payload: {
            error: { kunAntallUlesteError: errorText },
            loading: { isKunAntallUlesteLoading: false },
          },
        });
        if (e.ErrorMessage && e.StatusCode !== 403) {
          logError(e.ErrorMessage.Title, e.ErrorMessage.Body);
        }
      });
  } catch (e) {
    // this catch is for any other error that could happen during runtime
    const payload: Partial<GlobalState> = {
      error: { kunAntallUlesteError: errorText },
      loading: { isKunAntallUlesteLoading: false },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved henting av kun antall uleste', e);
  }
};

interface ResourceType {
  [key: string]: string | ResourceType;
}

const areEqual = (expected: ResourceType, compare: ResourceType): void => {
  try {
    Object.keys(expected).forEach(key => {
      if (typeof expected[key] === 'object') {
        areEqual(expected[key] as ResourceType, compare[key] as ResourceType);
      } else if (expected[key] !== compare[key]) {
        throw new Error(`I API-respons for headerfooter er "${compare[key]}" ikke lik fallback "${expected[key]}"`);
      }
    });
  } catch (e) {
    warn('Feil ved sammenlikning av headerfooter-data fra API med fallback-data', e);
  }
};
export const fallbackHeaderFooter: HeaderFooterFallbackResponse = {
  header: {
    globalMenu: {
      headerButtonMeny: 'Meny',
    },
    searchMenu: {
      headerButtonSok: 'Søk',
    },
    fasteTekster: {
      headerButtonLogin: 'Logg inn',
      headerButtonLoginUrl: '/auth/signin',
      headerButtonLogout: 'Logg ut',
      headerButtonLogoutUrl: '/auth/signout',
      hoppTilInnholdet: 'Hopp til innhold',
      headerButtonVarsler: 'Varsler',
      varslerAriaLabelNone: 'Ingen uleste varsler',
      varslerAriaLabelSome: 'Uleste varsler',
      varslerAriaLabelOne: 'Ett ulest varsel',
      varslerAriaLabelMultiple: '{count} uleste varsler',
    },
    profileMenu: {
      headerMenuProfileVarslingerDuHar: 'Du har',
      headerMenuProfileVarslingerIngen: 'ingen',
      headerMenuProfileVarslingerMeldinger: 'nye meldinger',
      headerMenuProfileVarslingerHendelser: 'nye hendelser',
      headerMenuProfileRepresentasjonerTitle: 'Hvem vil du bruke Helsenorge på vegne av?',
      headerMenuProfileLinksTitle: 'Profil og innstillinger',
      headerMenuProfileSistInnlogget: 'Du var sist innlogget ',
      errorVarslinger: 'Det har skjedd en feil. Varslingene kan ikke vises.',
    },
  },
};

export const getHeaderFooter = (dispatch: Dispatch<DispatchAction>, errorText: string, languageCode: LanguageLocales): void => {
  try {
    dispatch({
      type: ActionTypes.SetLoadingAction,
      payload: { loading: { isHeaderFooterLoading: true } },
    });
    ContentAPIGet<HeaderFooterResponse>('headerfooter', { languageCode })
      .then(headerFooter => {
        const headerFooterFallback = headerFooter ? merge(fallbackHeaderFooter, headerFooter) : fallbackHeaderFooter;
        const payload: GlobalState = {
          headerFooter: headerFooterFallback,
          loading: { isHeaderFooterLoading: false },
          error: { headerFooterError: undefined },
        };
        dispatch({ type: ActionTypes.GetHeaderFooterAction, payload });
        headerFooter?.footer && HNeventSetFooterData(headerFooter.footer);

        if (isDebug() && languageCode === LanguageLocales.NORWEGIAN) {
          areEqual(fallbackHeaderFooter as unknown as ResourceType, headerFooter as unknown as ResourceType);
        }
      })
      .catch(() => {
        // This catch is for asynchronous errors during the request
        dispatch({
          type: ActionTypes.error,
          payload: {
            headerFooter: fallbackHeaderFooter,
            error: { headerFooterError: errorText },
            loading: { isHeaderFooterLoading: false },
          },
        });
      });
  } catch (e) {
    // this catch is for any other error that could happen during runtime
    const payload: Partial<GlobalState> = {
      error: { headerFooterError: errorText },
      loading: { isHeaderFooterLoading: false },
    };
    dispatch({ type: ActionTypes.error, payload });
    logError('Feil ved henting av headerfooter fra CMS', e);
  }
};

export const setIsListenerReady = (dispatch: Dispatch<DispatchAction>): void => {
  dispatch({ type: ActionTypes.SetIsListenerReady, payload: { islistenerReady: true } });
};
