import React, { useContext, useEffect, useState } from 'react';

import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { debug } from '@helsenorge/framework-utils/logger';

import { globalStateContext } from './../../store';

import styles from './styles.module.scss';

const SkipToContent: React.FC = () => {
  const globalState = useContext(globalStateContext);
  const anchorTarget = getSidetittelId();

  const [hasAnchor, setHasAnchor] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      const isIdFound = document.getElementById(anchorTarget);
      if (!isIdFound) {
        debug(`Hopp til hovedinnhold med anchor id '${anchorTarget}' ble ikke funnet`);
        setHasAnchor(false);
      }
    }, 15000);
  }, []);

  return hasAnchor ? (
    <a className={styles['skip-to-content']} href={`#${anchorTarget}`}>
      {globalState.headerFooter.header.fasteTekster.hoppTilInnholdet}
    </a>
  ) : null;
};
export default SkipToContent;
