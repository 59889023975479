import { AnchorLinkTargets } from '@helsenorge/designsystem-react/components/AnchorLink';
import { NotificationPanelVariants } from '@helsenorge/designsystem-react/components/NotificationPanel';

import { HeaderFooterResponse } from '@helsenorge/core-cms/types/entities';
import { Personverninnstilling } from '@helsenorge/framework-utils/types/entities';

/* Menu */

export enum MenuType {
  search = 'search',
  profile = 'profile',
  basic = 'basic',
}

/* Actions */

export enum ActionTypes {
  GetUserAction,
  GetRepresentasjonAction,
  GetHendelserAction,
  GetAntallUlesteForAlle,
  GetKunAntallUleste,
  GetMeldingerAction,
  GetHeaderFooterAction,
  SetLoadingAction,
  SetRepresentasjonAction,
  SetIsAuthorizedAction,
  SetIsListenerReady,
  SetLanguage,
  SetVisPersonvelgerAction,
  SwitchUserAction,
  error,
}

/**
 * Session
 */
export interface AccessTokenExpirationOperationResponse {
  Expire: string;
}

export enum SessionTimeoutAction {
  Default = 0,
  RequestExtension = 1,
  RequestLogout = 2,
}

/**
 * Innlogget data
 */
export interface InnloggetDataResponse {
  InnloggetData: {
    RepresentertBruker: string;
    SistPaloggetDato: string;
    HarRepresentasjon: boolean;
    ErMellom12Og16: boolean;
    Scopes: string[];
    AktiveUtvalgtePersonverninnstillinger: Personverninnstilling[];
    HarInnsynssperre: boolean;
  };
}

/**
 * Driftspanel
 */
export type MeldingstypeVariants = 'infomelding' | 'driftsmelding';

interface BaseNotificationMessage {
  type: NotificationPanelVariants;
  title: string;
  introduction: string;
  url?: string;
  urlTitle?: string;
  target?: AnchorLinkTargets;
  moreDetails?: string;
  moreDetailsButtonText?: string;
  id: string;
  unixTimeStamp: number;
  messageType: MeldingstypeVariants;
}

export interface Driftsmelding extends BaseNotificationMessage {
  messageType: 'driftsmelding';
  appOnly?: boolean;
}

export interface Infomelding extends BaseNotificationMessage {
  messageType: 'infomelding';
  showAfterTitle?: boolean;
}

export type NotificationMessage = Driftsmelding | Infomelding;

/**
 * header-footer-tekster som har hardkodet fallback i tilfelle kall til contentapi feiler
 */
export type HeaderFooterFallbackResponse = HeaderFooterResponse & {
  header: {
    globalMenu: {
      headerButtonMeny: string;
    };
    searchMenu: {
      headerButtonSok: string;
    };
    fasteTekster: {
      headerButtonLogin: string;
      headerButtonLoginUrl: string;
      headerButtonLogout: string;
      headerButtonLogoutUrl: string;
      hoppTilInnholdet: string;
      headerButtonVarsler: string;
      varslerAriaLabelNone: string;
      varslerAriaLabelSome: string;
      varslerAriaLabelOne: string;
      varslerAriaLabelMultiple: string;
    };
    profileMenu: {
      headerMenuProfileVarslingerDuHar: string;
      headerMenuProfileVarslingerIngen: string;
      headerMenuProfileVarslingerMeldinger: string;
      headerMenuProfileVarslingerHendelser: string;
      headerMenuProfileRepresentasjonerTitle: string;
      headerMenuProfileLinksTitle: string;
      headerMenuProfileSistInnlogget: string;
      errorVarslinger: string;
    };
  };
};
